import Vue from "vue";
import { GridPlugin, ForeignKey, Page, Filter, Sort, Toolbar, Edit, DetailRow, CommandColumn } from "@syncfusion/ej2-vue-grids";
import { CustomUrlAdaptor } from "@/syncfusion/CustomUrlAdaptor";
import { DataManager } from "@syncfusion/ej2-data";
import SpinnerMixin from "@/mixins/spinner";
import TitleMixin from "@/mixins/title";
import GridMixin from "@/mixins/syncfusion/grid";
import { getStatisticTypeFormat } from "@/helpers/statisticType";
Vue.use(GridPlugin);
export default Vue.extend({
    components: {},
    mixins: [TitleMixin, SpinnerMixin, GridMixin],
    data() {
        return {
            offices: [],
            services: [],
            title: this.$t("intervalManagement.title"),
            data: new DataManager({
                url: process.env.VUE_APP_API_BASE_URL + "statistics/value-ranges/grid-data",
                removeUrl: process.env.VUE_APP_API_BASE_URL + "statistics/value-ranges/grid-delete",
                adaptor: new CustomUrlAdaptor()
            }),
            sortOptions: {
                columns: [
                    { field: "TypeName", direction: "Ascending" }
                ]
            },
            filterOptions: { type: "Menu" },
            pageSettings: { pageSize: 10 },
            toolbarOptions: [
                {
                    text: this.$t("shared.add"),
                    tooltipText: this.$t("shared.add"),
                    prefixIcon: "e-add",
                    id: "Custom_Add"
                }
            ],
            editSettings: {
                allowEditing: false,
                allowAdding: false,
                allowDeleting: true,
                showDeleteConfirmDialog: true,
                mode: "Dialog"
            },
            commands: [
                {
                    type: "Custom_Edit",
                    title: this.$t("shared.edit"),
                    buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" }
                },
                {
                    type: "Delete",
                    buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" }
                }
            ],
            childGrid: {
                queryString: 'ValueId',
                columns: [
                    { field: 'ValueId', headerText: this.$t('shared.id'), width: 0, visible: false, isPrimaryKey: true },
                    { field: 'InitialValue', headerText: this.$t('intervalManagement.initialValue'), format: "N2", width: 50 },
                    { field: 'FinalValue', headerText: this.$t('intervalManagement.finalValue'), format: "N2", width: 50 },
                    { field: 'Color', headerText: this.$t('intervalManagement.color'), width: 150, template: function () {
                            return {
                                template: Vue.component("colorColumn", {
                                    template: '<div v-bind:style="{width: \'60px\', height: \'21px\', backgroundColor: data.Color}"></div>'
                                })
                            };
                        }
                    }
                ],
                load: function () {
                    const grid = this;
                    grid.parentDetails.parentKeyFieldValue = grid.parentDetails.parentRowData['Id'];
                    const format = getStatisticTypeFormat(grid.parentDetails.parentRowData.Type);
                    const initialValueGridColumn = grid.getColumnByField("InitialValue");
                    initialValueGridColumn.format = format;
                    const finalValueGridColumn = grid.getColumnByField("FinalValue");
                    finalValueGridColumn.format = format;
                }
            }
        };
    },
    provide: {
        grid: [CommandColumn, ForeignKey, Page, Filter, Sort, Toolbar, Edit, DetailRow]
    },
    methods: {
        toolbarClick(args) {
            if (args.item.id == "Custom_Add") {
                this.$router.push("/statistics/value-ranges/create");
                return;
            }
        },
        commandClick(args) {
            if (args.commandColumn && args.commandColumn.type === "Custom_Edit") {
                this.$router.push(`/statistics/value-ranges/edit/${args.rowData.Id}`);
                return;
            }
        },
        onDetailDataBound(args) {
            const grid = args.detailElement.querySelector('.e-grid');
            const gridInstance = grid.ej2_instances[0];
            // Set child grid data source
            gridInstance.dataSource = args.data.Values;
        },
        actionComplete(args) {
            if (args.requestType === "delete") {
                this.$toast.showSuccessToast(this.$t("intervalManagement.delete.toastSuccessTitle"), this.$t("intervalManagement.delete.toastSuccessContent", {
                    name: `${args.data[0].Name} ${args.data[0].Surname}`
                }));
            }
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            this.offices = await this.$store.dispatch("office/getAll");
            this.services = await this.$store.dispatch("service/getAll", {
                load: true
            });
        }
        finally {
            this.hideSpinner();
        }
    }
});

export var StatisticType;
(function (StatisticType) {
    StatisticType[StatisticType["AppointmentsTrialStatistic"] = 10] = "AppointmentsTrialStatistic";
    StatisticType[StatisticType["AppointmentsTrialOverTrialWithoutNewAppointmentStatistic"] = 11] = "AppointmentsTrialOverTrialWithoutNewAppointmentStatistic";
    StatisticType[StatisticType["ServiceStatistics"] = 30] = "ServiceStatistics";
    StatisticType[StatisticType["ServiceTrendStatistic"] = 31] = "ServiceTrendStatistic";
    StatisticType[StatisticType["ServiceRevenueStatistic"] = 32] = "ServiceRevenueStatistic";
    StatisticType[StatisticType["EmployeeCostStatistic"] = 50] = "EmployeeCostStatistic";
    StatisticType[StatisticType["EmployeeProfitStatistic"] = 51] = "EmployeeProfitStatistic";
    StatisticType[StatisticType["EmployeeCostOverServiceRevenueStatistic"] = 52] = "EmployeeCostOverServiceRevenueStatistic";
    StatisticType[StatisticType["EmployeeWorkHoursStatistic"] = 53] = "EmployeeWorkHoursStatistic";
    StatisticType[StatisticType["CustomerAcquisitionChannelStatistic"] = 100] = "CustomerAcquisitionChannelStatistic";
    StatisticType[StatisticType["CustomersRetentionStatistic"] = 101] = "CustomersRetentionStatistic";
    StatisticType[StatisticType["CustomersTimeSlotsStatistic"] = 102] = "CustomersTimeSlotsStatistic";
    StatisticType[StatisticType["HealthPathsDurationStatistic"] = 120] = "HealthPathsDurationStatistic";
    StatisticType[StatisticType["HealthPathsDurationStandardDeviationStatistic"] = 121] = "HealthPathsDurationStandardDeviationStatistic";
    StatisticType[StatisticType["HealthPathsGymConvertionStatistic"] = 122] = "HealthPathsGymConvertionStatistic";
})(StatisticType || (StatisticType = {}));
;
export function getStatisticTypeFormat(statisticType) {
    switch (statisticType) {
        case null:
        case undefined:
            return "N2";
        case StatisticType.AppointmentsTrialStatistic:
        case StatisticType.ServiceStatistics:
        case StatisticType.ServiceTrendStatistic:
        case StatisticType.CustomerAcquisitionChannelStatistic:
        case StatisticType.CustomersTimeSlotsStatistic:
            return "N0";
        case StatisticType.HealthPathsDurationStatistic:
        case StatisticType.HealthPathsDurationStandardDeviationStatistic:
            return "N2";
        case StatisticType.ServiceRevenueStatistic:
        case StatisticType.EmployeeCostStatistic:
        case StatisticType.EmployeeProfitStatistic:
            return "C2";
        case StatisticType.AppointmentsTrialOverTrialWithoutNewAppointmentStatistic:
        case StatisticType.EmployeeCostOverServiceRevenueStatistic:
        case StatisticType.EmployeeWorkHoursStatistic:
        case StatisticType.CustomersRetentionStatistic:
        case StatisticType.HealthPathsGymConvertionStatistic:
            return "P2";
        default:
            throw new Error(`The value format for statistic type ${statisticType} is not managed.`);
    }
}
;
